<template>
  <transition name="el-fade-in-linear">
    <div class="video-popup" v-show="visible" @click="visible=false">
      <div class="video-content" @click.stop>
        <div class="video-popup-close" @click="visible=false">
          <i class="el-icon-circle-close"/>
        </div>
        <video-player ref="videoPlayer" class="vjs-default-skin vjs-big-play-centered" playsinline
                      :options="playerOptions"/>
      </div>
      <div class="video-popup-mobile-close" @click="visible=false">
        <i class="el-icon-circle-close"/>
      </div>
    </div>
  </transition>
</template>

<script>
  import {videoPlayer} from 'vue-video-player';
  import 'video.js/dist/video-js.css';

  export default {
    name: 'videoPopup',
    components: {
      videoPlayer,
    },
    data() {
      return {
        visible: false,
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
          autoplay: true, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: true, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
          ],
          // poster: require('@/assets/image/activity/minisite/video_cover.png'), //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: `This video can't be played right now. Please try again later`, //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        },
      };
    },
    watch:{
      visible(e){
        if(!e){
          this.$refs.videoPlayer.player.pause()
          this.$refs.videoPlayer.player.reset()
        }
      },
    },
    methods: {
      popup(url) {
        this.visible = true;
        this.$refs.videoPlayer.player.src(url)
        this.$refs.videoPlayer.player.autoplay(true)
        this.$nextTick(()=>{
          this.$refs.videoPlayer.player.play()
        })
      },
    },
  };
</script>

<style scoped>
  .video-popup {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  .video-popup-close, .video-popup-mobile-close {
    position: absolute;
    font-size: 50px;
    z-index: 20;
    color: #FFFFFF;
    right: -30px;
    top: -40px;
    cursor: pointer;
  }

  .video-popup-mobile-close {
    position: fixed;
    right: 30px;
    top: 40px;
    display: none;
  }

  .video-content {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    .video-popup-close {
      display: none;
    }
    .video-popup-mobile-close{
      display: block;
    }
  }
</style>
