<template>
  <div class="page" v-loading="pageLoading" element-loading-text="Loading...">
    <div class="banner" v-for="(item,index) in bannerList" :key="index">
      <img  :src="domainUrl+item.bannerUrl" alt="">
      <!-- <a class="registration" v-if="item.allowJump" :href="item.jumpUrl" target="_blank">{{item.btnValue}}</a> -->
      <a class="toYoutube" href="https://youtu.be/ijREQrJNv7E" target="_blank">Watch Video</a>
    </div>
    <section class="section">
      <div class="container">
        <div class="section-title">Join Us</div>
        <div class="join-us-content">
          <p class="join-us-p">Vintage photography was invented in the 1820s, producing the first successful monochrome
            images. This
            sparked an evolution in image technologies — from B&W to natural color, analog to digital, and generic
            cameras to AI-powered cameras — and, predictably will continue into the next two centuries.</p>
          <p class="join-us-p">Now, our AI- and ICT-driven world ushers in an intelligent era where all things are
            sensing,
            interconnected, and intelligent, and where connections between people are ubiquitous. The combination of
            intelligent security and vision perception systems will enable smart response of even-smarter machines.</p>
          <p class="join-us-p">Against this backdrop, HOLOWITS New Product Launch 2022 will be held online on June 30 at
            16:00PM (UTC+8).
            During this event, we will debut our new lineup of intelligent and cutting-edge products.We are honored to
            share this momentous occasion with you.</p>
          <div class="join-us-inscribe">
            <img class="join-us-inscribe-sign" :src="require('@/assets/image/activity/minisite/sign.png')" alt="">
            <div class="join-us-inscribe-text">Vinson Yang, CEO of HOLOWITS</div>
          </div>
          <div class="join-us-top-border"></div>
          <div class="join-us-bottom-border"></div>
        </div>
      </div>
    </section>
    <section class="video-section">
      <div class="container">
        <div class="video-content">
          <video-player ref="videoPlayer" class=" vjs-default-skin vjs-big-play-centered" playsinline
                        :options="playerOptions"/>
        </div>
      </div>
    </section>

    <div class="mixin-content">
      <section class="section">
        <div class="container">
          <div class="section-title">Agenda</div>
          <div class="agenda-content">
            <div class="agenda-item" v-for="(item,index) in agendas" :key="index">
              <div class="agenda-item-time">{{ item.time }}</div>
              <div class="agenda-item-desc">{{ item.desc }}</div>
              <div class="agenda-item-member">
                <div v-for="(mem,idx) in item.member" :key="idx">{{ mem }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="section-title">Speakers</div>
          <el-row class="speakers-content" :gutter="30">
            <el-col :md="8" :sm="12" :xs="24" v-for="(item,index) in speakers" :key="index">
              <div class="speakers-item">
                <img class="speakers-item-avatar" :src="item.avatar" alt="">
                <div class="speakers-item-name">{{ item.name }}</div>
                <div class="speakers-item-duty">{{ item.duty }}</div>
                <div class="speakers-item-department">{{ item.department }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="section-title">Videos</div>
          <el-row class="partner-voices-content" :gutter="30">
            <el-col :md="8" :sm="12" :xs="24" v-for="(item,index) in partnerVoices" :key="index" class="video-card">
              <div class="partner-voices-item" @click="jump2Other(item.poster)">
                <img :src="require('@/assets/image/activity/minisite/play.png')" class="partner-voices-item-btn">
                <img class="partner-voices-item-poster" :src="item.image" alt="">
                <div class="partner-voices-item-desc">
                  <div class="ellipsis-text" v-html="item.desc"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </section>
    </div>

    <VideoPopup ref="videoPopup"/>

  </div>
</template>

<script>

  import {videoPlayer} from 'vue-video-player';
  import 'video.js/dist/video-js.css';
  import {listBannerByPosition} from '@/api/bannerApi';
  import VideoPopup from './videoPopup';

  export default {
    name: 'minisite',
    components: {
      videoPlayer,
      VideoPopup,
    },
    data() {
      return {
        pageLoading: true,
        bannerList: [],
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: true, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4',
              src: 'https://resources.holowits.com.sg/material/570c69b4b4f64f7e86ab2f9d43814e71.mp4', //视频url地址
            }],
          poster: require('@/assets/image/common/video_cover.png'), //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: `This video can't be played right now. Please try again later`, //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        },

        agendas: [
          {
            time: '16:00-16:20',
            desc: 'HOLOWITS Industry Strategies and Product Launch',
            member: ['Jade Liu'],
          },
          {
            time: '16:20-16:40',
            desc: 'HOLOWITS Eco-Cube Camera Unboxing',
            member: ['Lance Tang', 'Elaine Li'],
          },
          {
            time: '16:40-17:00',
            desc: 'HOLOWITS Partners: Together for an Intelligent World',
            member: ['Saleem Ahmed Shaikh', 'Fred Saayman', 'Ivan Tan'],
          },
        ],

        speakers: [
          {
            avatar: require('@/assets/image/activity/minisite/Jade Liu.png'),
            name: 'Jade Liu',
            duty: 'Senior Product Manager',
            department: 'HOLOWITS',
          },
          {
            avatar: require('@/assets/image/activity/minisite/Lance Tang.png'),
            name: 'Lance Tang',
            duty: 'Product Manager',
            department: 'HOLOWITS',
          },
          {
            avatar: require('@/assets/image/activity/minisite/Elaine Li.png'),
            name: 'Elaine Li',
            duty: 'Marketing Manager',
            department: 'HOLOWITS',
          },
          {
            avatar: require('@/assets/image/activity/minisite/Saleem Ahmed Shaikh.png'),
            name: 'Saleem Ahmed Shaikh',
            duty: 'Managing Director',
            department: 'Magnus',
          },
          {
            avatar: require('@/assets/image/activity/minisite/Fred Saayman.png'),
            name: 'Fred Saayman',
            duty: 'Brand Director',
            department: 'Pinnacle ICT',
          },
          {
            avatar: require('@/assets/image/activity/minisite/Ivan Yan.png'),
            name: 'Ivan Tan',
            duty: 'Sales & Marketing Director',
            department: 'D-RON',
          },
        ],

        partnerVoices: [
          {
            poster: 'https://resources.holowits.com.sg/material/570c69b4b4f64f7e86ab2f9d93814e70.mp4',
            image: require('@/assets/image/activity/minisite/image_1.png'),
            desc: 'HOLOWITS AI Bullet Camera',
          },
          {
            poster: 'https://resources.holowits.com.sg/material/26fe7d6e76dd4cc386567367211d1923.mp4',
            image: require('@/assets/image/activity/minisite/image_2.png'),
            desc: 'HOLOWITS Dual Lens AI PTZ Dome Camera',
          },
          {
            poster: 'https://resources.holowits.com.sg/material/5b434888973d43bdb025443e4d4b7626.mp4',
            image: require('@/assets/image/activity/minisite/image_3.png'),
            desc: 'SuperColor in a box with HOLOWITS',
          },
          {
            poster: 'https://resources.holowits.com.sg/video/87bad9cb935c4cdfadff134bcf3b4441.mp4',
            image: require('@/assets/image/activity/minisite/image_5.png'),
            desc: 'Aaron, Solution Manager of D-RON Introduces HOLOWITS Technical Highlights <br>&nbsp;',
          },
          {
            poster: 'https://resources.holowits.com.sg/video/bf7f65131a964ade85c22dd0343f213d.mp4',
            image: require('@/assets/image/activity/minisite/image_6.png'),
            desc: 'Henry, Product Manager of D-RON Highly Recommonds HOLOWITS Core Products and Technologies',
          },
        ],
      };
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        listBannerByPosition({showPosition: 'minsite-header'}).then(response => {
          setTimeout(() => {
            this.pageLoading = false;
          }, 1000);
          this.bannerList = response.data.data;
        });
      },
      jump2Other(url) {
        this.$refs.videoPopup.popup(url);
      },
      bannerClick(item){
        this.$router.push({
          path:item.jumpUrl
        })
      },
    },
  };
</script>

<style scoped lang="less">
  .page {
    background-color: #FAF9FE;

    @media (min-width: 1500px) {
      .container {
        width: 1400px;
      }
    }
    @media (max-width: 1400px) {
      .video-content {
        padding: 0 !important;
        /deep/ .vjs-big-play-button {
          width: 60px!important;
          height: 60px!important;
          margin-top: -30px;
          margin-left: -30px;
        }
      }
    }
    @media (max-width: 750px) {
      .video-section {
        padding: 30px !important;
      }

      .section .join-us-content {
        padding: 20px 36px;

        .join-us-p {
          font-size: 16px;
          line-height: 2;
          margin-bottom: 20px;
        }

        .join-us-inscribe .join-us-inscribe-text {
          font-size: 18px;
          padding-right: 10px;
        }
      }

      .mixin-content .agenda-content {
        .agenda-item {
          flex-direction: column;
          align-items: flex-start;
          font-size: 16px;
          padding: 20px 10px;

          .agenda-item-time {
            text-align: left;
            padding: 0 40px;
          }

          .agenda-item-desc {
            margin: 10px 0;

            &:after, &:before {
              display: none;
            }
          }

          .agenda-item-member {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;

            div {
              margin-right: 15px;
            }
          }
        }
      }
    }
    .banner{
      position: relative;
      .registration{
        width: 250/14vw !important;
        height: 82/19.2vw !important;
        border-radius: 54/19.2vw;
        font-size: 28/19.2vw !important;
        font-weight: normal !important;
        text-align: center;
        line-height: 82/19.2vw;
        cursor: pointer;
        background-color: #c7000b;
        color: #fff;
        position: absolute;
        bottom: 94/4.2vw;
        left: 40%;
        margin-left: -125/19.2vw;
      }
      .toYoutube{
        width: 250/19.2vw !important;
        height: 82/19.2vw !important;
        border-radius: 54/19.2vw;
        font-size: 28/19.2vw !important;
        font-weight: normal !important;
        text-align: center;
        line-height: 82/19.2vw;
        cursor: pointer;
        background-color: #c7000b;
        color: #fff;
        position: absolute;
        bottom: 94/4.2vw;
        left: 62%;
        margin-left: -125/19.2vw;
      }
    }
    .banner img {
      width: 100%;
      display: block;
    }

    .section {
      padding: 40px 0;
      background-color: transparent;

      .section-title {
        font-size: 50px;
        font-weight: 400;
        color: #000000;
        text-align: center;
        position: relative;
        line-height: 70px;
        padding-bottom: 26px;
        margin-bottom: 40px;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 106px;
          height: 10px;
          left: 50%;
          margin-left: -53px;
          background: linear-gradient(159deg, rgba(195, 16, 222, 0.3) 0%, rgba(251, 97, 97, 0.5) 100%);
        }
      }
    }

    .join-us-content {
      padding: 36px 60px;
      position: relative;
      word-break: break-all;

      .join-us-p {
        font-size: 22px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 30px;
        line-height: 48px;
      }

      .join-us-inscribe {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .join-us-inscribe-sign {
          width: 187px;
          height: 81px;
          display: block;
          margin-bottom: 4px;
        }

        .join-us-inscribe-text {
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          text-align: right;
          padding-right: 26px;
          line-height: 50px;
        }
      }

      .join-us-top-border, .join-us-bottom-border {
        width: 60px;
        height: 4px;
        background: #DFD7FD;
        border-radius: 4px;
        position: absolute;

        &:after {
          content: '';
          width: 4px;
          height: 60px;
          background: #DFD7FD;
          border-radius: 4px;
          position: absolute;
        }
      }

      .join-us-top-border {
        top: 0;
        left: 0;

        &:after {
          top: -12px;
          left: 12px;
        }
      }

      .join-us-bottom-border {
        bottom: 0;
        right: 0;

        &:after {
          bottom: -12px;
          right: 12px;
        }
      }
    }

    .video-section {
      background-image: url(../../../assets/image/activity/minisite/background1.png);
      background-size: 100% auto;
      background-position: bottom;
      background-repeat: no-repeat;
      background-color: #f4f2fc;
      padding: 94px;

      .video-content {
        padding: 0 160px;
      }

      /deep/ .vjs-big-play-button {
        background-image: url(../../../assets/image/activity/minisite/play.png);
        background-size: 100%;
        width: 86px;
        height: 86px;
        border: none;
        border-radius: 50%;
        background-color: transparent;

        span {
          display: none;
        }
      }

      /deep/ .vjs-poster {
        background-size: 100% 100%;
        background-color: transparent;
      }

      /deep/ .video-js {
        border-radius: 16px;
        overflow: hidden;
        mask-image: radial-gradient(circle, white 100%, black 100%);
        transform: rotate(0.000001deg);
      }
    }

    .agenda-content {
      .agenda-item {
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #ECE8FB;
        padding: 34px 10px;
        min-height: 120px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        position: relative;

        &:before {
          content: '';
          width: 4px;
          height: 60px;
          background: #DFD7FD;
          border-radius: 4px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          content: '';
          width: 18px;
          height: 18px;
          background-image: url(../../../assets/image/activity/minisite/point.png);
          background-size: cover;
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        .agenda-item-time {
          width: 280px;
          max-width: 280px;
          min-width: 120px;
          text-align: center;
        }

        .agenda-item-desc {
          width: 100%;
          flex: 1;
          padding: 0 40px;
          position: relative;

          &:after, &:before {
            content: '';
            width: 1px;
            height: 56px;
            background-color: #ECE8FB;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &:before {
            left: 0;
          }

          &:after {
            right: 0;
          }
        }

        .agenda-item-member {
          max-width: 420px;
          min-width: 300px;
          padding: 0 40px;
          line-height: 1.8;
        }
      }
    }

    .mixin-content {
      background-image: url(../../../assets/image/activity/minisite/background2.png);
      background-size: auto 1165px;
      background-repeat: no-repeat;
      background-position: top 0;
    }

    .speakers-content {
      .speakers-item {
        height: 484px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EBE7FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        padding-top: 26px;
        text-align: center;
        line-height: 40px;
        font-weight: 400;

        .speakers-item-avatar {
          width: 274px;
          height: 274px;
          border-radius: 50%;
          object-fit: cover;
          background: #FAF9FE;
          opacity: 1;
          border: 3px solid #F2DAFF;
        }

        .speakers-item-name {
          font-size: 26px;
          color: #333333;
          margin: 20px 0;
        }

        .speakers-item-duty {
          font-size: 22px;
          color: #333333;
        }

        .speakers-item-department {
          font-size: 20px;
          color: #666666;
        }
      }
    }

    .partner-voices-content {
      .video-card {
        margin-top: 30px;
      }

      .partner-voices-item {
        background-color: #FFFFFF;
        border-radius: 8px;
        display: block;
        position: relative;
        overflow: hidden;

        .partner-voices-item-btn {
          cursor: pointer;
          width: 86px;
          height: 86px;
          top: 127px;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }

        .partner-voices-item-poster {
          width: 100%;
          height: 254px;
          object-fit: cover;
          display: block;
        }

        .partner-voices-item-desc {
          padding: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }

        .ellipsis-text {
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }

  }
</style>
